<template>
  <div>
    <Navbar page="Arquivadas" link="/projetos" nameLink="Gestão de Projetos" />
    <div class="mx-4 md:mx-8 mt-2 mb-8">
     
       <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Nome 
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Data 
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Opções
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in list" :key="item._id" class="hover:bg-gray-200 cursor-pointer">

                    <td class="px-2 py-4">
                      <div class="flex items-center">
                        <div v-if="item.data" class="text-sm_2 text-gray-500">
                          {{ item.nome }} 
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-4">
                      <div class="flex items-center">
                        <div v-if="item.data" class="text-sm_2 text-gray-500">
                          {{ item.data | moment("DD/MM/YYYY HH:mm") }} 
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-4 text-sm font-medium">

                      <button
                        @click="restaurar(item._id)" 
                        class="text-white text-base hover:bg-blue-500 bg-blue-700 rounded py-1 mx-1 my-1 px-2">
                        Restaurar
                      </button>

                      <button
                        @click="remove(item._id)" 
                        class="text-white text-base hover:bg-red-500 bg-red-700 rounded py-1 mx-1 my-1 px-2">
                        Excluir
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <pagination v-model="page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: 'tarefas',
      projetoAtual: '',
      list: [],
      total: 0,
      page: 1,
      perPage: 15,
    }
  },
  methods: {
    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/arquivadas/list`, { limit: this.perPage });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
      this.projetoAtual = listReq.data.projetoAtual;
    },

    async setPage(page) {
      this.page = page;
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      const listReq = await this.$http.post(`/v1/${this.route}/arquivadas/list`, { limit, skip  });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    async restaurar(id) {
      this.$confirm({
        title: 'Restaurar Projeto',
        message: `Deseja restaurar o Projeto ? É necessário arquivar o projeto atual. Confirma ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route}/arquivar`, { nome: this.projetoAtual });
          const req = await this.$http.post(`/v1/${this.route}/restaurar-arquivada`, {  arquivada: id });
          this.$vToastify.success("Restaurado com sucesso!");
          this.$store.commit('setEmpresa', req.data.empresa);
          this.$router.push({path: `/projetos`});
        }
      })
    },

    async remove(id) {
      this.$confirm({
        title: 'Remover arquivada',
        message: `Deseja remover arquivada selecionada ? `,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.delete(`/v1/${this.route}/arquivadas/${id}`);
          this.$vToastify.success("Removido com sucesso!");
          this.setPage(this.page);
          this.grafico();
        }
      })
    },

  },
  async beforeMount() {
    this.start();
  },
}
</script>